const BREAKPOINTS = {
  xs: 350,
  sm: 576,
  md: 769,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1600,
  xxxxl: 1800,
}

const parameters = {
  isMobile: window.innerWidth < BREAKPOINTS.lg,
  isMobileLandscape: window.innerWidth < 1025,
  APP_WITH_LOADER: true,
  IS_TOUCH_SCREEN: (function() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
  })(),

  isMobileOrTablet: false,
  BREAKPOINTS,

  EVENTS: {
    PS_TRANSITION_OUT: "PS_TRANSITION_OUT",
  },
}
parameters.isMobileOrTablet = (parameters.isMobile || parameters.IS_TOUCH_SCREEN)

export default parameters;
