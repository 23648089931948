import Highway from "@dogstudio/highway"

// components
import Blob from "../components/Blob"

// utils
import parameters from "../utils/parameters";

export default class Renderer404 extends Highway.Renderer {

    onEnter() {

        const wrapper = this.wrap.querySelector("#page-404")
        this.wrapper = wrapper

        // if (!parameters.isMobile) {
        //     this.background = this.wrapper.querySelector('.bg-name-title')
        //     gsap.to(this.background, 1, { bottom: 0, ease: 'Bounce.easeOut', delay: 3 });
        // }
    }

    onEnterCompleted() {

        if (!this.wrapper) {
            console.error("wrapper not found")
            return false
        }

        this.goContact = this.wrapper.querySelector(`.js__go-work.${parameters.isMobile ? "mobile" : "desktop"}`)
        this.goContactBlob = new Blob({
            wrapper: this.goContact,
            size: .3,
            amplitude: 3
        })

    }
}