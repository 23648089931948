// libs
import Highway from "@dogstudio/highway";
import gsap from "gsap/gsap-core";
import parameters from "../utils/parameters";

// Renderers
import RendererHomepage from "../renderers/RendererHomepage";
import RendererAbout from "../renderers/RendererAbout";
import RendererProductSingle from "../renderers/RendererProductSingle";
import RendererContact from "../renderers/RendererContact";
import Renderer404 from "../renderers/Renderer404";
import ContactTransition from "../transitions/ContactTransition";

// Transitions
import DefaultTransition from "../transitions/DefaultTransition";
import ProductSingleTransition from "../transitions/ProductSingleTransition";
import HomeTransition from "../transitions/HomeTransition";
import AboutToContactTransition from "../transitions/AboutToContactTransition";
import BetweenProductTransition from "../transitions/BetweenProductTransition";

const H = new Highway.Core({
  renderers: {
    homepage: RendererHomepage,
    about: RendererAbout,
    "product-single": RendererProductSingle,
    contact: RendererContact,
    404: Renderer404,
  },

  transitions: {
    default: DefaultTransition,
    "product-single": ProductSingleTransition,
    homepage: HomeTransition,
    contact: ContactTransition,
    404: DefaultTransition,

    contextual: {
      "about-to-contact": AboutToContactTransition,
      "in-product": BetweenProductTransition,
    },
  },
});


const header = {
  containerOver: document.querySelector(".header__container-over"),
  openMenu: document.querySelector("#openMenu"),
  containerUnder: document.querySelector(".header__container-under"),
  container: document.querySelector(".header__container"),


}
H.on("NAVIGATE_OUT", ({ from, trigger, location }) => {

  if (!parameters.isMobile && trigger && trigger.dataset && trigger.dataset.menu === "1") {

    var tlOut = gsap.timeline({
      onStart(){
        header.openMenu.style.pointerEvents = "none"
      },
      onComplete(){
        header.openMenu.style.pointerEvents = ""
        header.containerOver.style.transition = ""
        header.container.style.transition = ""
        header.container.style.opacity = ""
      },
    });
    tlOut.fromTo(
      header.containerOver,
      { opacity: 1 },
      {
        opacity: 0,
        duration: 0.3,
        onStart: () => {
          header.containerOver.style.transition = "none"

        }
      }
    );

    tlOut.fromTo(
      header.containerUnder,
      { yPercent: 0 },
      {
        yPercent: 77,
        duration: .3,
        ease: "power2.out",
        onComplete: () => {
          header.openMenu.classList.add("animating")

        }
      }
    );

    tlOut.to(
      header.containerUnder,
      {
        yPercent: 175,
        duration: .6,
        ease: "power2.out",
        onComplete: () => {


          // hide
          header.container.style.transition = "none"
          header.container.style.opacity = "0"

          // reset
          header.openMenu.checked = false;
          header.openMenu.classList.remove("animating")
          header.containerOver.style.opacity = "";
          header.containerUnder.style.transform = ""

        }
      }
    );
    tlOut.add(function(){
        // show back
        header.container.style.opacity = ""
    }, ">.3")

  } else if (!!parameters.isMobile)
    header.openMenu.checked = false;
});

H.on("NAVIGATE_IN", ({ to, trigger, location }) => {
  // remove current menu itemm
  const currentMenuItem = document.querySelector(
    ".header__container-items li.current"
  );
  if (currentMenuItem) {
    currentMenuItem.classList.remove("current");
  }

  // set next current menu item
  const nextCurrentMenuItem = to.page.querySelector(
    ".header__container-items li.current"
  );
  if (nextCurrentMenuItem) {
    const itemId = nextCurrentMenuItem.id;
    document.querySelector("#" + itemId).classList.add("current");
  }
});

export default H;
