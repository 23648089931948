// libs
import gsap from "gsap"

// components
import AbstractComponent from "./AbstractComponent" 

// utils
import { randomInteger, map } from "../utils/math"
import simplex from "../utils/simplex"


export default class SliderMask extends AbstractComponent{
    
    constructor(wrapper){
        super(wrapper)

        // dom
        this.innerEl = this.wrapper.querySelector(".js__slider-mask-inner")

        // parameters
        this.noiseTick = 16e-3
        this.numPoints = 6
        this.amplitude = 40
        this.points = []

        this.wrapperHeight = this.wrapper.clientHeight
        this.wrapperWidth = this.wrapper.clientWidth

        // others
        this.DURATION = 1.0

        this.didMount()
    }

    didMount(){
        super.didMount()
        this.initSVG()
    }

    initSVG(){
        this.svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
        this.svg.setAttribute("viewBox", `0 0 ${this.wrapper.clientWidth} ${this.wrapper.clientHeight + this.amplitude}`)
        this.innerEl.appendChild(this.svg)
        
        this.path = document.createElementNS("http://www.w3.org/2000/svg", "path")
        this.svg.appendChild(this.path)

        // create points
        this.points = this.createPoints()

        // init noise displacement
        this.updatePoints()

        // draw
        this.drawPath()


        // console.log(this.svg.attributes.viewBox, this.innerEl.clientHeight)
    }

    drawPath(){

        // compute
        let D = `M 0,0 `

        this.points.forEach(point => {
            D += `L ${point.screenX},${point.screenY} `
        })

        D += ` L ${this.points[this.points.length - 1].screenX},0 Z`

        // set path
        this.path.setAttribute("d", D)

    }

    createPoints(){

        let points = []

        for(let i = 0; i < this.numPoints; i++){

            let x = (i / (this.numPoints - 1))
            let y = randomInteger(0, 1)

            points.push({
                screenX: this.wrapperWidth * x,
                screenY: this.wrapperHeight + this.amplitude * y,
                x,
                y,
                originalX: x,
                originalY: y,
                noiseOffset: randomInteger(0, 1000)
            })

        }

        return points

    }

    updatePoints(){

        this.points = this.points.map(point => {

            const noise = simplex.noise2D(point.originalX + point.noiseOffset, point.originalY)

            // move forward into noise
            point.noiseOffset += this.noiseTick

            // apply amplitude
            point.y = map(noise, -1, 1, 0, 1)
            point.screenY = this.wrapperHeight + this.amplitude * point.y

            return point
        })

    }

    animateExit(onComplete){

        const tl = gsap.timeline()
        
        tl.to(this.innerEl, {
            scaleY: -1,
            y: "100%",
            duration: 0,
        })

        tl.to(this.innerEl,{
            y: -this.amplitude,
            scaleY: -1,
            duration: this.DURATION,
            ease: "power2.inOut",
            onUpdate: () => {
                this.updatePoints()
                this.drawPath()
            },
            onComplete: onComplete,
        })

        return tl

    }

    animateEntrance(onComplete){

        gsap.to(this.innerEl, {
            y: "-100%",
            duration: this.DURATION,
            ease: "power2.inOut",
            onUpdate: () => {
                this.updatePoints()
                this.drawPath()
            },
            onComplete,
        })

    }
}