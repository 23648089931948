//libs
import Highway from "@dogstudio/highway";
import gsap from "gsap";

// utils
import emitterInstance from "../utils/emitter";
import parameters from "../utils/parameters";

export default class BetweenProductTransition extends Highway.Transition {
  // Built-in methods
  in({ from, to, trigger, done }) {
    from.remove();

    gsap.from(to, {
      opacity: 0,
      duration: 0.5,
    });

    done();
  }

  out({ from, trigger, done }) {
    emitterInstance.emit(parameters.EVENTS.PS_TRANSITION_OUT, done, false);
  }
}
