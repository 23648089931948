export function filter(array, fn){

    let included = [], excluded = []

    for(let i = 0; i < array.length; i++){

        if(fn(array[i])){
            included.push(array[i])
        } else {
            excluded.push(array[i])
        }

    }

    return {
        included,
        excluded
    }
}
