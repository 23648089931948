// libs
import Highway from "@dogstudio/highway";
import Blob from "../components/Blob";
import gsap from "gsap";

// components
import CopyEmail from "../components/CopyEmail";
import FormMailer from "../components/FormMailer";
import FormSubjects from "../components/FormSubjects";
import parameters from "../utils/parameters";

// transition
import ContactTransition from "../transitions/ContactTransition";

// utils
// utils
import { createPrng } from "../utils/prng";
import { map } from "../utils/math";
const prng = createPrng(1);

export default class RendererContact extends Highway.Renderer {
  enterAnimation(wrapper) {
    // blob
    const blobLabel = wrapper.querySelectorAll(
      ".page-contact__form-subjects-item-label"
    );
    const blobSVG = wrapper.querySelectorAll(
      ".js__form-subject-item .blob__svg"
    );

    // delay
    const delays = Array(blobSVG.length)
      .fill(1)
      .map(() => prng.randomFloat(0, 0.5));

    const tl = gsap.timeline();

    blobSVG.forEach((el) => (el.style.transition = "none"));
    tl.from(
      blobSVG,
      {
        scale: 0,
        duration: 1,
        delay: (index) => delays[index],
        ease: "power2.inOut",
        onComplete: () => {
          blobSVG.forEach((el) => (el.style.transition = ""));
          blobSVG.forEach((el) => (el.style.transform = ""));
        },
      },
      0
    );

    tl.from(
      blobLabel,
      {
        delay: (index) => delays[index],
        opacity: 0,
        duration: 1,
        ease: "power2.inOut",
      },
      "<.5"
    );
  }

  onEnter() {
    // reset scroll
    window.scrollTo(0, 0);

    const wrapper = this.wrap.querySelector("#page-contact");

    if (!wrapper) {
      console.error("wrapper not found");
      return false;
    }

    const subjectsContainer = wrapper.querySelector(".js__form-subjects");
    const formContainer = wrapper.querySelector(".js__form");

    this.blobs = [...wrapper.querySelectorAll(".js__form-subject-item")].map(
      (el) => {
        let size = el.dataset.blobSize ? parseFloat(el.dataset.blobSize) : 1;
        let sizeMobile = el.dataset.blobSizeMobile
          ? parseFloat(el.dataset.blobSizeMobile)
          : 1;

        const finalSize = parameters.isMobile ? sizeMobile * .9 : size;

        return new Blob({
          wrapper: el,
          size: finalSize,
          amplitude: finalSize < 0.4 ? 2.5 : undefined,
          numPoints: 7,
        });
      }
    );

    this.copyEmail = new CopyEmail(wrapper.querySelector(".js__copy-email"));
    this.formMailer = new FormMailer(formContainer);
    this.formSubjects = new FormSubjects(
      subjectsContainer,
      wrapper.querySelector("#form-subject"),
      subjectsContainer,
      formContainer,
      wrapper
    );

    this.enterAnimation(wrapper);
  }

  onLeave() {
    this.copyEmail.willUnmount();
    this.formMailer.willUnmount();
  }
}
