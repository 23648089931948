// components
import AbstractComponent from "./AbstractComponent" 

// utils


export default class CopyEmail extends AbstractComponent{
    
    constructor(wrapper){
        super(wrapper)

        // dom
        this.tooltip = this.wrapper.querySelector(".js__copy-email-tooltip")
        this.btn = this.wrapper.querySelector(".js__copy-email-btn")
        this.labelEl = this.wrapper.querySelector(".js__copy-email-label")

        // parameters
        this.TOOLTIP_DURATION_STAYING = 1500
        this.TOOLTIP_DURATION_LEAVE = 150

        // others
        this.copiedLabel = this.wrapper.dataset.copiedLabel
        this.originalLabel = this.labelEl.textContent

        this.didMount()
    }

    didMount(){
        super.didMount()
        
    }

    // Events

    onClick = event => {

        const email = event.currentTarget.textContent
        navigator.clipboard.writeText(email).then(() => {

            this.labelEl.textContent = this.copiedLabel
            this.wrapper.classList.add("copied")
            this.wrapper.classList.add("copied-in")

            setTimeout(() => {
                this.wrapper.classList.remove("copied-in")

                setTimeout(() => {
                    this.wrapper.classList.remove("copied")
                    this.labelEl.textContent = this.originalLabel
                }, this.TOOLTIP_DURATION_LEAVE)

            }, this.TOOLTIP_DURATION_STAYING)

        })

    }

    attachEvents(){
        this.btn.addEventListener("click", this.onClick)
    }

    removeEvents(){
        this.btn.removeEventListener("click", this.onClick)
    }
}