// App style
import "../scss/style.scss"
import 'swiper/swiper.scss';

// Components
import Loader from "./components/Loader"
import parameters from "./utils/parameters";

// Loader
let loaderElement = document.getElementById("loader")
if(parameters.APP_WITH_LOADER){
    new Loader(loaderElement)
} else {
    loaderElement.style.display = "none"
    document.getElementById("App").style.opacity = "1"
}
import router from "./utils/router"

// console.log("1.0.2")
console.log("%c Designed by : Thabian Blondon", "font-size: 16px; background-color: #C2A58E; color: #FFFFFF; padding: 1rem;")
console.log("%c Coded by : Morgane Sauneuf & Keny Zachelin", "font-size: 16px; background-color: #DBCABD; color: #000000; padding: 1rem;")