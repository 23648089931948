// libs
import gsap from "gsap"
import parameters from "../utils/parameters";

// components
import AbstractComponent from "./AbstractComponent";
import LoaderMask from "./LoaderMask";

export default class Loader extends AbstractComponent{

    constructor(wrapper, transition){
        super(wrapper)


        // parameters
        this.allMediaLoaded = false
        this.animatingEnter = true

        // Transition

        // dom
        this.images = [...document.querySelectorAll("[data-loader-image]")]
        this.progress = this.wrapper.querySelector(".js__progress")
        this.progressLabel = this.wrapper.querySelector(".js__progress-label")
        this.progressContainer = this.wrapper.querySelector(".js__progress-container")
        this.progressTitle = this.wrapper.querySelector(".js__progress-title")
        this.progressBg = this.wrapper.querySelector(".js__progress-bg")

        this.homeBgTitle = document.querySelector(".js__home-bg-title")

        // others
        this.imageCount = this.images.length
        this.ANIMATING_PERCENT_DURATION = 1.3
        this.mask = new LoaderMask(wrapper, this.progressBg)

        this.didMount()
    }

    // Animations

    animatePercent(nextPercent){

        gsap.to(this.progress, {
            width: `${Math.floor(nextPercent * 100)}%`,
            duration: this.ANIMATING_PERCENT_DURATION,
            ease: "power3.out",
        })

    }

    animateMediaLoaded(){

        const percent = (this.images.length - this.imageCount) / this.images.length
        this.animatePercent(percent)

    }

    animateLeave(){

        const tl = gsap.timeline({
            onStart: () => {
                document.body.classList.add("loader-leaving")

                this.unlockScroll()
            },

            onComplete: () => {
                document.body.classList.add("loader-has-left")
                this.wrapper.style.display = "none"
            }
        })

        const homeImageItems = document.body.querySelectorAll(".page-home__images-item")


        // fade out progress + label
        tl.to(this.progressLabel, {
            opacity: 0,
            y: "-50%",
            duration: .35
        }, 0)

        tl.to(this.progressContainer, {
            opacity: 0,
            duration: .35
        }, .3)


        // fade out title
        tl.to(this.progressTitle, {
            scale: 1,
            ease: "power3.inOut",
            duration: .75,
        }, ">.2")

        if(this.homeBgTitle){
            tl.to(this.homeBgTitle, {
                scale: 1,
                ease: "power2.inOut",
                duration: .75,
            }, "<")
        }

        
        const loaderTitle = this.progressTitle
        tl.to(this.progressBg, {
            y: "-100%",
            ease: "power3.inOut",
            duration: 1.,
            onStart: () => {
                this.mask.startAnimate()
            },
            onComplete: () => {
                this.mask.stopAnimate()
            },
            onUpdate: function(){
                if(this.progress() > .5){
                    loaderTitle.style.display = "none"
                }
            }
        }, "<")

        // Animating home grid
        if(homeImageItems && homeImageItems.length){
            homeImageItems.forEach(el => el.dataset.translate = el.style.transform)
            if(!parameters.isMobile){
                //
            } else {

                const foo = {bar: 1}

                tl.from(foo, {
                    bar: .85,
                    ease: "power2.inOut",
                    duration: .75,
                    onUpdate: () => {
                        homeImageItems.forEach((el) => {
                            el.style.transform = `${el.dataset.translate} scale(${foo.bar})`
                        })
                    }
                }, "<.3")

                tl.from(homeImageItems, {
                    opacity: 0,
                    ease: "power2.inOut",
                    duration: .5
                }, "<")
            }
        }
        
    }

    // Actions
    onAllMediaLoaded(){

        this.allMediaLoaded = true

        // all media loaded after animating enter has finished
        // so we need to leave now
        if(!this.animatingEnter){
            setTimeout(() => this.animateLeave(), this.ANIMATING_PERCENT_DURATION * 1000)
        } 

    }

    didMount(){

        super.didMount()

        // show loader
        document.body.classList.add("has-loader")
        document.getElementById("App").style.opacity = "1"

        // lock scroll
        this.lockScroll()

        // init big title
        this.progressTitle.style.transform = "translate(-50%, -50%) scale(.8)"

        const tl = gsap.timeline({
            delay: 1,
            onComplete: () => {

                
                // we finished animating enter
                this.animatingEnter = false

                // all media loaded while doing this animation
                if(this.allMediaLoaded){

                    // percent go to 100%
                    gsap.to(this.progress, {
                        width: "100%",
                        duration: this.ANIMATING_PERCENT_DURATION,
                        delay: .4,
                        ease: "power3.out",
                        onComplete: () => {
                            this.animateLeave()
                        }
                    })

                }

            }
        })


        tl.from(this.progressLabel, {
            opacity: 0,
            y: "50%",
            duration: .35
        })

    }



    // Helpers
    isLoaded = image =>  image.complete && image.naturalHeight !== 0
    hasFailed = image =>  image.complete && image.naturalHeight === 0

    lockScroll(){
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.scrollY}px`;
    }

    unlockScroll(){
        document.body.style.position = '';
        document.body.style.top = '';
    }


    // Events
    onImageLoaded = event => {

        // One media loaded
        this.imageCount--

        // has finish animating enter so we notify each loaded media
        if(!this.animatingEnter){
            this.animateMediaLoaded()
        }

        // All images loaded
        if(this.imageCount === 0){
            this.onAllMediaLoaded()
        }

    }

    onImageFailed = event => {
        this.onImageLoaded()
    }

    attachEvents(){
        super.attachEvents()

        // there is no image to load
        // no need to listen to images
        if(this.imageCount === 0){
            this.onAllMediaLoaded()
            return;
        }

        this.images.forEach(image => {

            const alreadyLoaded = this.isLoaded(image)
            const hasFailed = this.hasFailed(image)

            // loaded before JS
            if(alreadyLoaded){
                this.onImageLoaded()
            }

            // Failed before JS
            else if(hasFailed){
                this.onImageFailed()
            }
            
            // Listen with JS
            else {
                image.addEventListener("load", this.onImageLoaded)
                image.addEventListener("error", this.onImageFailed)     
            }

        })
    }


    willUnmount(){
        super.willUnmount()
        this.mask.willUnmount()
    }
}