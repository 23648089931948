function getRAF(){

    let listeners = []
    let rafID = null

    function onTick(){
        
        rafID = requestAnimationFrame(onTick)
        listeners.forEach(fn => fn())

    }

    return {

        // Properties
        listeners,

        // Methods
        subscribe: function subscribe(listener){
            listeners.push(listener)
        },
        unsubscribe: function unsubscribe(listener){
            listeners = listeners.filter(l => l !== listener)    
        },

        start: function(){
            rafID = requestAnimationFrame(onTick)
        },
        stop: function(){
            cancelAnimationFrame(rafID)
        }
    }

}

const raf = getRAF()
raf.start()

export default raf