export default class AbstractComponent{

    /**
     * 
     * @param {Element} wrapper 
     */
    constructor(wrapper){
        this.wrapper = wrapper
    }

    // Events
    attachEvents(){

    }

    removeEvents(){

    }

    // Lifecycle

    didMount(){
        this.attachEvents()
    }

    willUnmount(){
        this.removeEvents()
    }

}