// components
import AbstractComponent from "../AbstractComponent";

// utils
import parameters from "../../utils/parameters"

export default class Parallax extends AbstractComponent {

    constructor(wrapper) {
        super(wrapper)
        this.didMount()
    }

    didMount() {
        super.didMount()

        // curtains effect on picture
        if (!parameters.isMobile) {
            let observer = new IntersectionObserver((observable) => {
                if (observable[0].intersectionRatio > 0.5) {
                    this.wrapper.style.setProperty('--width', 0)
                    observer.unobserve(this.wrapper)
                }
            }, {
                threshold: [0.6]
            });
            observer.observe(this.wrapper)
        }
    }
}