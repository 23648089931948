// libs

// components
import AbstractComponent from "./AbstractComponent" 

// utils
import { randomInteger, map } from "../utils/math"
import raf from "../utils/raf"
import simplex from "../utils/simplex"
import parameters from "../utils/parameters"


export default class LoaderMask extends AbstractComponent{
    
    constructor(wrapper, bg){
        super(wrapper)

        // dom
        this.bg = bg

        // parameters
        this.noiseTick = 16e-3
        this.numPoints = 6
        this.height = 50
        this.amplitude = parameters.isMobile ? 15 : 25
        this.points = []

        // others

        this.didMount()
    }

    didMount(){
        super.didMount()
        this.initSVG()        
    }

    initSVG(){
        this.svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
        this.svg.classList.add("loader__bg-mask")
        this.svg.setAttribute("viewBox", `0 0 ${window.innerWidth} ${this.height + this.amplitude}`)
        this.bg.appendChild(this.svg)
        
        this.path = document.createElementNS("http://www.w3.org/2000/svg", "path")
        this.svg.appendChild(this.path)

        // create points
        this.points = this.createPoints()

        // draw
        this.drawPath()
    }

    drawPath(){

        // compute
        let D = `M 0,0 L 0,0 `

        this.points.forEach(point => {
            D += `L ${point.screenX},${point.y} `
        })

        D += ` L ${this.points[this.points.length - 1].screenX},0 Z`

        // set path
        this.path.setAttribute("d", D)

    }

    createPoints(){

        let dist = 0
        let points = []

        for(let i = 0; i < this.numPoints; i++){

            // dist += x

            let x = (i / (this.numPoints - 1))
            let y = randomInteger(this.height, this.height + this.amplitude)

            points.push({
                screenX: window.innerWidth * x,
                x,
                y,
                originalX: x,
                originalY: y,
                noiseOffset: randomInteger(0, 1000)
            })

        }

        return points

    }

    updatePoints(){

        this.points = this.points.map(point => {

            const noise = simplex.noise2D(point.originalX + point.noiseOffset, point.originalY)

            // move forward into noise
            point.noiseOffset += this.noiseTick

            // apply amplitude
            point.y = map(noise, 0, 1, this.height, this.height + this.amplitude)

            return point
        })

    }


    animate = () => {

        this.updatePoints()
        this.drawPath()

    }

    startAnimate(){
        raf.subscribe(this.animate)
    }

    stopAnimate(){
        raf.unsubscribe(this.animate)
    }

    // Events

    attachEvents(){

    }

    removeEvents(){

    }
}