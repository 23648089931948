// components
import { lerp } from "../../utils/math";
import AbstractComponent from "../AbstractComponent";

// utils
import parameters from "../../utils/parameters"
import raf from "../../utils/raf";

export default class SpinOnScroll extends AbstractComponent {

    constructor(wrapper, smoothScroll) {
        super(wrapper)

        this.DRAG_EASING = parameters.isMobile ? .2: .1;
        this.scrollHeight = window.innerHeight;
        this.lastPos = 0;
        this.currentPos = this.lastPos;

        this.smoothScroll = smoothScroll

        this.didMount()
    }

    update = () => {
        this.currentPos = lerp(this.currentPos, this.lastPos, this.DRAG_EASING);
        this.wrapper.style.transform = "translate(-50%, -50%) rotate(" + (this.currentPos*100)%360 + "deg)";
    }

    didMount() {
        super.didMount()
        raf.subscribe(this.update)
    }

    onScroll = event => {
        this.lastPos = event.scroll.y / event.limit.y;
    }

    attachEvents() {
        this.smoothScroll.on("scroll", this.onScroll)
    }

    removeEvents() {
        document.removeEventListener('scroll', this.onScroll)
        raf.unsubscribe(this.update)
    }
}