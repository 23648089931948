// libs
import gsap from "gsap"
import {spline} from "@georgedoescode/spline"

// components
import AbstractComponent from "./AbstractComponent" 

// utils
import raf from "../utils/raf"
import {map} from "../utils/math"
import parameters from "../utils/parameters"
import simplex from "../utils/simplex"

const MAX_RADIUS = parameters.isMobile ? 85 : 115
const MIN_RADIUS = parameters.isMobile ? 50 : 50
export default class Blob extends AbstractComponent{
    
    constructor({wrapper, size, radius = null, eventTarget = null, amplitude = null, numPoints = 6, hideAtBeginning = false, withExtraBorder = false, withEvents = true}){
        super(wrapper)

        // dom
        this.eventTarget = eventTarget ? eventTarget : wrapper

        // parameters
        this.radius = radius ? radius : map(size, 0, 1, MIN_RADIUS, MAX_RADIUS)

        // others
        this.amplitude = amplitude || 6
        this.numPoints = numPoints
        this.noiseStep = .005
        this.safeOverflow = this.amplitude
        this.hideAtBeginning = hideAtBeginning
        this.withExtraBorder = withExtraBorder
        this.withEvents = withEvents
        this.playing = false
                
        this.didMount()

    }

    updatePoints(tick){
        for (let i = 0; i < this.points.length; i++) {
            const point = this.points[i];
        
            // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
            const nX = simplex.noise2D(point.noiseOffsetX, point.noiseOffsetX);
            const nY = simplex.noise2D(point.noiseOffsetY, point.noiseOffsetY);
            // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
            const x = map(nX, -1, 1, point.originX - this.amplitude, point.originX + this.amplitude);
            const y = map(nY, -1, 1, point.originY - this.amplitude, point.originY + this.amplitude);
        
            // update the point's current coordinates
            point.x = x;
            point.y = y;
        
            // progress the point's x, y values through "time"
            point.noiseOffsetX += tick ? tick : this.noiseStep;
            point.noiseOffsetY += tick ? tick : this.noiseStep;
        }

        this.path.setAttribute("d", spline(this.points, 1, true))        

    }

    animate = () => {
        this.updatePoints()
    }

    didMount(){
        super.didMount()
        
        // init size
        this.wrapper.style.width = this.wrapper.style.height = `${this.radius * 2}px`
        this.wrapper.style.position = "relative"

        // append svg
        this.initSVG()

        // init blob
        this.updatePoints()

    }

    initSVG(){

        const size = `${this.radius * 2}px`

        // container
        this.svgWrapper = document.createElement("div")
        this.svgWrapper.classList.add("blob")
        this.svgWrapper.style.width = this.svgWrapper.style.height = size
        this.wrapper.appendChild(this.svgWrapper)

        // <svg>
        this.svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
        this.svg.classList.add("blob__svg")
        this.svg.setAttribute("viewBox", `0 0 ${this.radius * 2} ${this.radius * 2}`)
        this.svgWrapper.appendChild(this.svg)

        // <path>
        this.points = this.createPoints()
        this.path = document.createElementNS("http://www.w3.org/2000/svg", "path")
        this.path.setAttribute("d", spline(this.points, 1, true))
        this.svg.appendChild(this.path)

        if(this.hideAtBeginning){
            this.svg.style.opacity = 0
        }


        if(this.withExtraBorder){
            this.extraBorder = this.svg.cloneNode(true)
            this.extraBorder.classList.remove("blob__svg")
            this.extraBorder.classList.add("blob__svg-extra")
            this.svgWrapper.appendChild(this.extraBorder)
        }

    }

    startAnimate(){

        if(this.playing) return;

        if(parameters.isMobileOrTablet){
            console.warn("no animation in mobile")
            return false
        }

        this.startTime = performance.now()
        raf.subscribe(this.animate)
        this.playing = true
    }

    stopAnimate(animate){


        if(!this.playing) return;

        if(animate){

            this.stopAnimate()

            const tl = gsap.to({progress: 0}, {
                progress: 1,
                duration: 1,
                onUpdate: () => {

                    const p = tl.progress()
                    const step = this.noiseStep * (1 - p)
                    this.updatePoints(step)
                }
            })
        } else {
            raf.unsubscribe(this.animate)
        }

        this.playing = false
    }

    // Events
    onMouseEnter = event => {
        this.startAnimate()
        this.svgWrapper.classList.add("is-hover")
    }

    onMouseLeave = event => {
        this.stopAnimate(true)
        this.svgWrapper.classList.remove("is-hover")
    }

    attachEvents(){

        if(this.withEvents){
            this.eventTarget.addEventListener("mouseenter", this.onMouseEnter)
            this.eventTarget.addEventListener("mouseleave", this.onMouseLeave)
        }

    }

    removeEvents(){
        this.stopAnimate()

        if(this.withEvents){
            this.eventTarget.removeEventListener("mouseenter", this.onMouseEnter)
            this.eventTarget.removeEventListener("mouseleave", this.onMouseLeave)
        }

    }

    // Helpers
    createPoints() {
        const points = [];
        // how many points do we need
        // used to equally space each point around the circle
        const angleStep = (Math.PI * 2) / this.numPoints;
        // the radius of the circle
        const rad = this.radius - this.safeOverflow
      
        for (let i = 1; i <= this.numPoints; i++) {
          // x & y coordinates of the current point
          const theta = i * angleStep;
      
          const x = (this.radius) + Math.cos(theta) * rad
          const y = (this.radius) + Math.sin(theta) * rad
      
          // store the point
          points.push({
            x: x,
            y: y,
            /* we need to keep a reference to the point's original {x, y} coordinates 
            for when we modulate the values later */
            originX: x,
            originY: y,
            // more on this in a moment!
            noiseOffsetX: Math.random() * 1000,
            noiseOffsetY: Math.random() * 1000,
          });
        }
      
        return points;
      }
      
}