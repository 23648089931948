

//libs

import Highway from '@dogstudio/highway'
import gsap from "gsap"

export default class AboutToContactTransition extends Highway.Transition {

    // Built-in methods
    in({ from, to, trigger, done }) {

        from.remove()

        const elementFades = to.querySelector(".page-contact__header")
        gsap.from(elementFades, {
            opacity: 0,
            duration: 1,
        })

        done()
    }
  
    out({ from, trigger, done }) {

        
        const bgTitle = from.querySelector(".bg-name-title")
        const bound = bgTitle.getBoundingClientRect()

        // everything else
        const goContact = from.querySelector(".js__go-contact")
        const lastSection = from.querySelector(".section__exhibitions")

        let y = -bound.top + (window.innerHeight / 2) - (bound.height / 2)

        const tl = gsap.timeline({onComplete: done})

        tl.to(bgTitle, {
            y,
            duration: 1,
            ease: "power2.inOut",
        })

        // tl.to(goContact, {
        //     scale: 0,
        //     duration: 1.5,
        //     ease: "power2.inOut",
        // }, 0)

        tl.to([goContact, lastSection], {
            opacity: 0,
        }, 0)
    }
}