// components
import AbstractComponent from "../AbstractComponent";

// utils
import parameters from "../../utils/parameters"

export default class HoverTitleSimple extends AbstractComponent {

    constructor(wrapper, smoothScroll) {
        super(wrapper)

        this.smoothScroll = smoothScroll
        // dom
        this.mobileClickContainer = wrapper.querySelector(".page-about__banner-container-title")
        this.titleContainer = wrapper.querySelector("#titleContainer")
        this.images = wrapper.getElementsByClassName("object-img")

        // parameters
        this.MOUSE_X = 0;
        this.MOUSE_Y = 0;
        this.LAST_MOUSE_X = 0;
        this.LAST_MOUSE_Y = 0;
        this.NB_IMAGES = this.images.length;
        this.timeout;
        this.imgTimeout;
        this.maxImgTimeout = 2000;

        this.mousePosInterval;
        this.mouseSpeed;

        this.currentImg = 0;

        this.MOBILE_ID = 0;

        this.scrollY = 0

        this.didMount()
    }

    showImg = (id, xpos, ypos) => {
        this.images[id].className = "object-img " + (id % 2 == 0 ? "even" : "odd");
        this.images[id].style.top = ypos - 250 + "px";
        this.images[id].style.left = xpos - 125 + "px";

        let timeout;
        timeout = setTimeout(() => {
            this.images[id].className = "object-img"
            clearTimeout(timeout)
        }, 1000)
    }

    didMount = () => {
        super.didMount()

        this.smoothScroll.on("scroll", this.onScroll)

        if(parameters.IS_TOUCH_SCREEN){
            document.querySelector(".tap").style.display = "block"
        }
    }

    onScroll = e => {
        this.scrollY = e.scroll.y
    }

    onMouseMove = e => {
        this.MOUSE_X = e.clientX
        this.MOUSE_Y = e.clientY + this.scrollY
    }

    animationImagesTrails = (xpos, ypos, lastxpos, lastypos) => {
        if (lastxpos != xpos || lastypos != ypos) {
            this.showImg(this.currentImg, xpos, ypos)
            this.NB_IMAGES - 1 == this.currentImg ? this.currentImg = 0 : this.currentImg++
        }
    }

    onMouseOver = () => {
        this.mousePosInterval = setInterval(() => {
            let movementX = Math.abs(this.MOUSE_X - this.LAST_MOUSE_X);
            let movementY = Math.abs(this.MOUSE_Y - this.LAST_MOUSE_Y);
            let movement = Math.sqrt(movementX * movementX + movementY * movementY);

            this.mouseSpeed = Math.round(movement);
            this.imgTimeout = (1 / Math.sqrt(this.mouseSpeed)) * this.maxImgTimeout;

            this.timeout = setTimeout(() => {
                this.animationImagesTrails(this.MOUSE_X, this.MOUSE_Y, this.LAST_MOUSE_X, this.LAST_MOUSE_Y)
            }, !!isFinite(this.imgTimeout) ? this.imgTimeout : this.maxImgTimeout)

            this.LAST_MOUSE_X = this.MOUSE_X
            this.LAST_MOUSE_Y = this.MOUSE_Y
        }, 300)
    }

    onMouseOut = e => {
        clearTimeout(this.timeout)
        clearInterval(this.mousePosInterval)
    }

    onClick = e => {
        this.showImg(this.MOBILE_ID, e.pageX, e.pageY)
        this.NB_IMAGES - 1 == this.MOBILE_ID ? this.MOBILE_ID = 0 : this.MOBILE_ID++
    }

    attachEvents = () => {
        if (!!parameters.isMobileOrTablet) {
            this.mobileClickContainer.addEventListener('click', this.onClick)
        } else {
            this.titleContainer.addEventListener('mousemove', this.onMouseMove)
            this.titleContainer.addEventListener('mouseenter', this.onMouseOver)
            this.titleContainer.addEventListener('mouseleave', this.onMouseOut)
        }
    }

    removeEvents = () => {
        if (!!parameters.isMobileOrTablet) {
            this.mobileClickContainer.removeEventListener('click', this.onClick)
        } else {
            this.titleContainer.removeEventListener('mousemove', this.onMouseMove)
            this.titleContainer.removeEventListener('mouseenter', this.onMouseOver)
            this.titleContainer.removeEventListener('mouseleave', this.onMouseOut)
        }
        clearTimeout(this.timeout)
        clearInterval(this.mousePosInterval)
    }
}