// libs
import gsap from "gsap";
import Highway from "@dogstudio/highway";
import Swiper, { Pagination } from "swiper";

Swiper.use([Pagination]);

// utils
import parameters from "../utils/parameters";
import emitterInstance from "../utils/emitter";

// components
import Blob from "../components/Blob";
import SliderCursor from "../components/SliderCursor";
import SliderMask from "../components/SliderMask";

const DOT_SVG = `
    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.0940997 3.65866C-0.54437 0.42301 2.22233 -0.116265 3.68549 0.0185537C7.19706 1.3128 5.94674 4.06312 4.88263 5.27649C3.55248 6.0854 0.732569 6.89431 0.0940997 3.65866Z"/>
    </svg>
`;

export default class RendererProductSingle extends Highway.Renderer {
  onEnter() {
    document.body.classList.add("no-menu");
  }

  onEnterCompleted() {
    const wrapper = this.wrap.querySelector("#page-product-single");
    this.wrapper = wrapper;

    if (!wrapper) {
      console.error("wrapper not found");
      return false;
    }

    if (!parameters.isMobile) {
      this.swiper = new Swiper(".swiper", {
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: (index, className) =>
            `<span class="${className}">${DOT_SVG}</span>`,
        },
        on: {
          touchMove: (swiper, event) => {
            if (this.sliderCursor) {
              this.sliderCursor.onSliderTouchMove(swiper, event);
            }
          },
          afterInit: () => {
            this.sliderMask = new SliderMask(
              wrapper.querySelector(".js__slider-mask")
            );

            // animate entrance
            this.sliderMask.animateEntrance(() => {
              wrapper.classList.add("is-ready");
            });
          },
        },
      });

      if(!parameters.isMobileOrTablet){
        this.sliderCursor = new SliderCursor(
          wrapper.querySelector(".js__slider-cursor"),
          this.swiper.el
        );
      }
    } else {
      wrapper.classList.add("is-ready");
    }

    this.backButton = wrapper.querySelector(".js__back");
    this.backButtonBlobContainer = wrapper.querySelector(".js__back-blob");
    this.backButtonBlob = new Blob({
      wrapper: this.backButtonBlobContainer,
      size: 0.3,
      radius: 35,
      eventTarget: this.backButton,
      amplitude: 2,
    });

    // events
    emitterInstance.once(
      parameters.EVENTS.PS_TRANSITION_OUT,
      this._onTransitionOut
    );
  }

  _onTransitionOut = (done, removeMenu = true) => {

    const tl = gsap.timeline({ onComplete: done });

    if (this.sliderMask) {
      tl.add(this.sliderMask.animateExit(() => {}));
    }

    tl.to(
      this.wrapper,
      {
        opacity: 0,
        duration: this.sliderMask ? 0.5 : 1,
        onComplete: () => {
          // reset scroll
          window.scrollTo(0, 0);

          // put header back
          if (removeMenu) {
            document.body.classList.remove("no-menu");
          }

          done();
        },
      },
      ">-.2"
    );
  };

  onLeave() {
    if (this.sliderCursor) {
      this.sliderCursor.willUnmount();
    }
  }

  onLeaveCompleted() {

    if (!parameters.isMobile) {
      this.swiper.destroy();
    }
  }
}
