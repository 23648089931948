

//libs

import Highway from '@dogstudio/highway'
import gsap from "gsap"
import parameters from '../utils/parameters'
export default class HomeTransition extends Highway.Transition {

    // Built-in methods
    in({ from, to, trigger, done }) {

        from.remove()
        done()


        // dom
        const gridContainer = to.querySelector(".js__grid-container")
        const items = to.querySelectorAll(".page-home__images-item")
        items.forEach(el => el.dataset.translate = el.style.transform)

        gridContainer.style.transition = "none"

        // animation
        const tl = gsap.timeline({
            onComplete: () => {
                gridContainer.style.transition = ""
            }
        })

        tl.from(gridContainer, {
            opacity: 0,
            ease: "power2.out",
            duration: .55,
        }, 0)



        if(!parameters.isMobile){
            tl.fromTo(gridContainer, {
                scale: .95,
            }, {
                scale: 1,
                ease: "power2.inOut",
    
                duration: 1
            }, 0)
    
        } else {

            const foo = {bar: 1}

            tl.from(foo, {
                bar: .95,
                ease: "power2.inOut",
                onUpdate: () => {
                    items.forEach((el) => {

                        el.style.transform = `${el.dataset.translate} scale(${foo.bar})`

                    })
                }
            }, 0)
        }


    }
  
    out({ from, trigger, done }) {

        // dom
        const gridContainer = from.querySelector(".js__grid-container")
        const items = from.querySelectorAll(".page-home__images-item")
        items.forEach(el => el.dataset.translate = el.style.transform)

        gridContainer.style.transition = "none"

        // animation
        const tl = gsap.timeline({
            onComplete: done
        })
        tl.to(gridContainer, {
            opacity: 0,
            ease: "power2.out",
            duration: .55,
        }, 0)



        if(!parameters.isMobile){
            tl.fromTo(gridContainer, {
                scale: 1,
            }, {
                scale: .85,
                ease: "power2.inOut",
    
                duration: 1
            }, 0)
    
        } else {

            const foo = {bar: 1}

            tl.to(foo, {
                bar: .85,
                ease: "power2.inOut",
                onUpdate: () => {
                    items.forEach((el) => {

                        el.style.transform = `${el.dataset.translate} scale(${foo.bar})`

                    })
                }
            }, 0)
        }

        tl.to(from, {
            opacity: 0,
        }, "<.25")

    }
}