// libs
import gsap from "gsap"

// components
import AbstractComponent from "./AbstractComponent" 

// utils
import parameters from "../utils/parameters"


export default class FormSubjects extends AbstractComponent{
    
    constructor(wrapper, hiddenInput, subjectsContainer, formContainer, pageWrapper){
        super(wrapper)

        // dom
        this.subjects = this.wrapper.querySelectorAll(".js__form-subject-item")
        this.hiddenInput = hiddenInput
        this.pageWrapper = pageWrapper        

        this.subjectsContainer = subjectsContainer
        this.formContainer = formContainer
        this.groupContainer = subjectsContainer.parentElement
        this.productList = formContainer.querySelector(".page-contact__form-optional-input")
        this.productListInput = this.productList.querySelector("select")
        
        // parameters
        this.hasSelected = false
        this.isBelowXL = window.innerWidth < parameters.BREAKPOINTS.xl

        // others

        this.didMount()
    }

    didMount(){
        super.didMount()
        this.hideForm()

        this.initSubjectItems()

        // hide product list
        this.productList.style.height = "0px"
        this.productList.style.opacity = "0"
    
    }

    initSubjectItems(){

        let items = []

        this.subjects.forEach(element => {

            let position = parameters.isMobile ? element.dataset.mobilePos : element.dataset.pos
            position = position.split(";")

            element.style.left = position[0] + "px"            
            element.style.top = position[1] + "px" 
            
            items.push({
                el: element,
                posX: Number(position[0]),
                posY: Number(position[1]),
                height: element.clientHeight,
                width: element.clientWidth,
            })
        })

        // lowest item
        let sortedByTop = [...items].sort((a, b) => -( (a.posY - b.posY) + (a.height - b.height) ))
        let lowestItem = sortedByTop[0]
        this.wrapper.style.height = `${lowestItem.height + lowestItem.posY}px`

        let sortedByLeft = [...items].sort((a, b) => -( (a.posX - b.posX) + (a.width - b.width) ))
        let rightestItem = sortedByLeft[0]
        this.wrapper.style.width = `${rightestItem.width + rightestItem.posX}px`
    }

    hideForm(){
        

        if(this.isBelowXL){

            gsap.to(this.formContainer, {
                height: 0,
                opacity: 0,
                visibility: "hidden",
                duration: 0,
            })

        }

        else {

            gsap.to(this.formContainer, {
                visibility: "hidden",
                opacity: 0,
                duration: 0,
            })

            gsap.to(this.subjectsContainer, {
                position: "absolute",
                left: "50%",
                top: 0,
                x: "-50%",
                duration: 0,
            })
        }
    }

    showForm(){

        const tl = gsap.timeline()

        this.pageWrapper.classList.add("form-is-visible")

        if(this.isBelowXL){
                            
            const bodyScrollHeight = document.body.scrollHeight

            tl.to(this.formContainer, {
                height: "auto",
                duration: 1,
            })

            tl.to({myValue: 0}, {
                myValue: 1,
                ease: "power2.inOut",
                onUpdate(){
                    window.scrollTo(0, this.progress() * bodyScrollHeight) 
                }
            }, 0)

            tl.to(this.formContainer, {
                opacity: 1,
                visibility: "",
                duration: .5,
            })
            
        }

        else {

            tl.to(this.subjectsContainer, {
                position: "absolute",
                left: 0,
                x: 0,
                duration: 1,
                ease: "power2.inOut",
                onComplete: () => {
                    this.subjectsContainer.style.position = ""
                    this.subjectsContainer.style.left = ""
                    this.subjectsContainer.style.transform = ""
                }
            })
    
            tl.to(this.formContainer, {
                visibility: "",
                opacity: 1,
                duration: .5,
                ease: "power4.inOut",
            })

        }

    }

    // Events

    onClickSubject = event => {

        const target = event.currentTarget

        // change input value
        const text = event.currentTarget.textContent
        this.hiddenInput.value = text

        // show form
        if(!this.hasSelected){
            this.showForm()
        }

        const shouldDisplayProductList = typeof target.dataset.displayProduct !== "undefined"
        const tl = gsap.timeline()

        if(shouldDisplayProductList){
            tl.to(this.productList, {
                height: "auto",
            })

            tl.to(this.productList, {
                opacity: 1,
            })
        } else {
            tl.to(this.productList, {
                height: 0,
                opacity: 0
            })

            this.productListInput.selectedIndex = null
        }

        // subjects has been selected
        this.wrapper.classList.add("has-selected")

        if(this.hasSelected){
            this.hasSelected.classList.remove("is-selected")
        }

        this.hasSelected = target
        this.hasSelected.classList.add("is-selected")

        // animate
        // do...

    }

    attachEvents(){
        this.subjects.forEach(element => element.addEventListener("click", this.onClickSubject))
    }

    removeEvents(){
        this.subjects.forEach(element => element.addEventListener("click", this.onClickSubject))
    }
}