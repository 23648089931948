// libs

// components
import AbstractComponent from "./AbstractComponent" 
import Blob from "./Blob"

// utils


export default class FormMailer extends AbstractComponent{
    
    constructor(wrapper){
        super(wrapper)

        // dom
        this.inputFullname = this.wrapper.querySelector("#form-fullname")
        this.inputEmail = this.wrapper.querySelector("#form-email")
        this.inputMessage = this.wrapper.querySelector("#form-message")
        this.inputSubject = this.wrapper.querySelector("#form-subject")
        this.inputProduct = this.wrapper.querySelector("#form-product")

        this.submitter = this.wrapper.querySelector("#form-submit")

        // parameters
        this.submitButtonBlob = new Blob({
            wrapper: this.submitter,
            radius: 32,
            numPoints: 7,
            amplitude: 2,
            withExtraBorder: true
        })
        

        // others

        this.didMount()
    }

    onSucceed(){
        this.wrapper.classList.add("submitted")

        this.inputFullname.readOnly = true
        this.inputEmail.readOnly = true
        this.inputMessage.readOnly = true
        this.inputProduct.readOnly = true
    }

    onFailed(){
        this.wrapper.classList.add("failed")
    }

    async sendEmail(){

        const fullname = this.inputFullname.value
        const email = this.inputEmail.value
        const message = this.inputMessage.value
        const subject = this.inputSubject.value
        const product = this.inputProduct.value
        
        try {

            this.wrapper.classList.add("loading")

            const res = await fetch("/api/mailer", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    fullname,
                    email,
                    message,
                    subject,
                    product,
                })
            })

            this.wrapper.classList.remove("loading")

            if(res.status === 200){
                this.onSucceed()
            } else {
                this.onFailed()
            }

        } catch (error) {
            this.onFailed()
        }

    }

    didMount(){
        super.didMount()

        // setTimeout(() => {
        //     this.wrapper.classList.add("ready")

        //     setTimeout(() => {
        //         this.wrapper.classList.add("loading")

        //         setTimeout(() => {
        //             this.wrapper.classList.add("submitted")
        //         }, 2000)

        //     }, 1000)

        // }, 1000)

    }

    // Events

    onSubmit = event => {
        event.preventDefault()
        
        if(this.formIsReady()){
            this.sendEmail()
        }
    }

    onInput = event => {
        
        // Check input are not empty
        if(this.formIsReady()){
            this.wrapper.classList.add("ready")
            this.isReady = true
        } 
        
        // ready
        else {
            this.wrapper.classList.remove("ready")
            this.isReady = false
        }

    }

    attachEvents(){
        this.submitter.addEventListener("click", this.onSubmit)

        this.inputFullname.addEventListener("input", this.onInput)
        this.inputEmail.addEventListener("input", this.onInput)
        this.inputMessage.addEventListener("input", this.onInput)
        this.inputProduct.addEventListener("input", event => {
            event.currentTarget.style.color = "#000"
        }, {once: true})
    }

    removeEvents(){
        this.submitter.removeEventListener("click", this.onSubmit)
    }

    // Helpers
    formIsReady(){
        return this.inputMessage.value && this.inputFullname.value && this.inputEmail.value
    }
}