// libs
import gsap from "gsap"

// components
import AbstractComponent from "./AbstractComponent" 
import Blob from "./Blob"

// utils
import raf from "../utils/raf"
import {lerp} from "../utils/math"

export default class SliderCursor extends AbstractComponent{
    
    constructor(wrapper, sliderElement, smoothScroll, alwaysVisible = false){
        super(wrapper)

        // dom
        this.sliderElement = sliderElement
        this.movableCursor = this.wrapper.querySelector(".js__slider-cursor-movable")

        // parameters
        this.EASING = .15
        this.isHoverSlider = false
        this.alwaysVisible = alwaysVisible

        const middle = {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
        }

        this.position = {
            last: {x: middle.x, y: middle.y},
            current: {x: middle.x, y: middle.y}
        }

        // others
        this.smoothScroll = smoothScroll
        this.virtualScrollY = 0

        this.didMount()
    }

    didMount(){
        super.didMount()
        raf.subscribe(this.animate)

        this.blob = new Blob({
            wrapper: this.movableCursor.querySelector(".slider-cursor-icon"),
            radius: 40,
            amplitude: 2,
            withEvents: false,
        })

        if(this.alwaysVisible){
            this.onMouseEnter()
        }
    }

    // Actions
    animate = () => {

        // lerp position
        this.position.current.x = lerp(this.position.current.x, this.position.last.x, this.EASING)
        this.position.current.y = lerp(this.position.current.y, this.position.last.y, this.EASING)
        
        // move cursor
        this.movableCursor.style.transform = `translate(${this.position.current.x}px, ${this.position.current.y + this.virtualScrollY}px)`
    }

    moveCursor = (x, y) => {
        this.position.last.x = x
        this.position.last.y = y
    }

    // Events

    onSliderTouchMove = (swiper, event) => {
        this.moveCursor(event.x, event.y)
    }

    onMousemove = event => {
        this.moveCursor(event.clientX, event.clientY)
    }

    onMouseEnter = event => {
        this.wrapper.classList.add("active")
        this.isHoverSlider = true
        this.blob.startAnimate()
    }

    onMouseLeave = event => {
        this.wrapper.classList.remove("active")
        this.isHoverSlider = false
        this.blob.stopAnimate()
    }

    onScroll = event => {
        this.virtualScrollY = event.scroll.y
    }

    attachEvents(){
        document.addEventListener("mousemove", this.onMousemove)

        if(this.sliderElement){
            this.sliderElement.addEventListener("mouseenter", this.onMouseEnter)
            this.sliderElement.addEventListener("mouseleave", this.onMouseLeave)    
        }

        if(this.smoothScroll){
            this.smoothScroll.on("scroll", this.onScroll)
        }
    }

    removeEvents(){

        document.removeEventListener("mousemove", this.onMousemove)
        if(this.sliderElement){
            this.sliderElement.removeEventListener("mouseenter", this.onMouseEnter)
            this.sliderElement.removeEventListener("mouseleave", this.onMouseLeave)
        }

    }

    willUnmount(){

        super.willUnmount()
        raf.unsubscribe(this.animate)
        this.blob.willUnmount()

        gsap.to(this.wrapper, {
            opacity: 0,
            duration: .65,
        })
    }
}