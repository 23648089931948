

//libs

import Highway from '@dogstudio/highway'
import gsap from "gsap"

export default class DefaultTransition extends Highway.Transition {

    // Built-in methods
    in({ from, to, trigger, done }) {

        gsap.from(to, {
            opacity: 0,
            duration: 1,
            onStart(){
                from.remove()
            },
            onComplete: done

        })
        
    }
  
    out({ from, trigger, done }) {

        gsap.to(from, {
            opacity: 0,
            onComplete: done
        })
    }
}