
export function clamp(x, min, max){
    return Math.min(Math.max(x, min), max)
}

export function lerp(value1, value2, amount) {
    amount = amount < 0 ? 0 : amount;
    amount = amount > 1 ? 1 : amount;
    return value1 + (value2 - value1) * amount
}

export function nearestSquareNumber(n){
    return Math.pow(Math.round(Math.sqrt(n)), 2)
}

export function map(n, start1, end1, start2, end2) {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2
}

export function randomInteger( low, high ) {
	return low + Math.floor( Math.random() * ( high - low + 1 ) )
}