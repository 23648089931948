// libs
import gsap from "gsap"

// components
import { clamp, lerp, nearestSquareNumber } from "../utils/math"
import AbstractComponent from "./AbstractComponent" 

// utils
import parameters from "../utils/parameters"
import { filter } from "../utils/misc"
import raf from "../utils/raf"

export default class HomeGrid extends AbstractComponent{
    
    constructor(wrapper){
        super(wrapper)

        // dom
        this.gridItems = [...wrapper.querySelectorAll(".js__grid-item")]
        this.gridDraggable = wrapper.querySelector(".js__grid-draggable")
        this.gridContainer = wrapper.querySelector(".js__grid-container")
        this.gridFilter = wrapper.querySelector(".js__grid-filter")

        // others
        this.screen = {
            width: window.innerWidth,
            height: window.innerHeight
        }
        this.pointer = {
            last: {x: 0, y: 0}, // based on pointer event movement
            isDragging: false
        }
        this.position = {
            current: {x: 0, y: 0}, // based on RAF update
            last: {x: 0, y: 0}, // based on pointer event movement
        }
        this.isFiltering = false
        this.canMove = true
        
        // parameters
        this.DRAG_EASING = parameters.isMobile ? .2: .1
        this.DRAG_QUANTITY = parameters.isMobile ? 2 : 1

        this.GRID_ITEM_SIZE = parameters.isMobile ? 48 :  22 // 22vw
        this.GRID_ITEM_SIZE_Y = parameters.isMobile ? 65 :  22 // 22vw
        

        this.GRID_ODD_OFFSET_Y = parameters.isMobile ? 0 : this.GRID_ITEM_SIZE_Y / 2
        this.GRID_MARGIN_Y = parameters.isMobile ? 6 : 2

        this.GRID_NOT_MOVING_THRESHOLD = 1e-4

        this.setGridPadding()

        this.didMount()
    }

    animate = () => {

        // compute position
        this.position.current.x = lerp(this.position.current.x, this.position.last.x, this.DRAG_EASING)
        this.position.current.y = lerp(this.position.current.y, this.position.last.y, this.DRAG_EASING)

        // is the grid still moving ?
        let diff = (this.position.current.x - this.position.last.x) + (this.position.current.y - this.position.last.y)
        diff = Math.abs(diff)

        if(diff < this.GRID_NOT_MOVING_THRESHOLD){
            this.gridDraggable.style.willChange = ""
            return false
        }

        // apply
        this.applyTranslation()

    }

    applyTranslation(){

        let x = ((this.position.current.x / this.screen.width) * 100) + "vw"
        let y = ((this.position.current.y / this.screen.width) * 100) + "vw"

        this.gridDraggable.style.transform = `translate(${x}, ${y})`
        this.gridDraggable.style.willChange = `transform`
    }


    // Actions
    
    createGrid(items, display = true){

        let count = items.length
        let grid = this.findSuitableRowsCols(count)
        let x = 0,
            y = 0,
            index = 0

        let itemPositions = []

        // The grid size
        this.max_cols = grid.cols
        this.max_rows = grid.rest ? grid.rows + 1 : grid.rows
    
        for(let row = 0; row < this.max_rows; row++){

            let maxCols = grid.rest && row === this.max_rows - 1 ? grid.rest : grid.cols

            for(let col = 0; col < maxCols; col++){

                // compute position
                x = this.GRID_ITEM_SIZE * col
                y = this.GRID_ITEM_SIZE_Y * row + (this.GRID_MARGIN_Y * row) /** space between */ + (col % 2 === 0 ? 0 : this.GRID_ODD_OFFSET_Y) /** offset y between col */
                // y = this.GRID_ITEM_SIZE_Y * row
    
                // display
                if(display){
                    items[index].style.transform = `translate(${x}vw, ${y}vw)`
                    items[index].style.width = `${this.GRID_ITEM_SIZE}vw`
                    items[index].style.height = `${this.GRID_ITEM_SIZE_Y}vw`
                }

                // store
                if(!display){
                    itemPositions.push({
                        x,
                        y
                    })
                }

                index++

            }

        }

        let width = this.max_cols * this.GRID_ITEM_SIZE
        let height = this.max_rows * this.GRID_ITEM_SIZE_Y + (this.max_rows - 1) * this.GRID_MARGIN_Y + this.GRID_ODD_OFFSET_Y
        // let height = this.max_rows * this.GRID_ITEM_SIZE_Y

        if(display){
            this.gridDraggable.style.width = `${width}vw`
            this.gridDraggable.style.height = `${height}vw`
        }

        this.gridSize = {
            width: width * 1e-2,
            height: height * 1e-2
        }

        // compute translations
        this.computeTranslationLimit()

        return {
            itemPositions,
            gridWidth: width,
            gridHeight: height
        }

    }

    didMount(){
        super.didMount()        

        // create grid
        this.createGrid(this.gridItems)

        // start raf
        raf.subscribe(this.animate)

        // start at top when mobile
        if(parameters.isMobile){
            this.position.current.y = this.position.last.y = 0
            this.applyTranslation()
        }

        this.onResize()
    }

    // Events

    onPointerDown = event => {

        if(!this.canMove) return false

        this.pointer.isDragging = true
        this.pointer.last.x = event.x
        this.pointer.last.y = event.y

        setTimeout(() => {

            if(this.pointer.isDragging){
                this.wrapper.classList.add('is-dragging')
            }

        }, 100);           
    }

    onPointerUp = event => {

        this.pointer.isDragging = false
        this.wrapper.classList.remove('is-dragging')

        // enable click links
        this.gridDraggable.style.pointerEvents = ""
        
        // reset cursor
        document.body.style.cursor = ""
    }

    onPointerMove = event => {

        
        if(this.pointer.isDragging){

            // disable click links
            this.gridDraggable.style.pointerEvents = "none"

            // cursor grabbing
            document.body.style.cursor = "grabbing"
            
            // add difference between last move
            this.position.last.x += (event.x - this.pointer.last.x) * this.DRAG_QUANTITY
            this.position.last.y += (event.y - this.pointer.last.y) * this.DRAG_QUANTITY
            
            this.position.last.x = clamp(this.position.last.x, -this.limitX, this.limitX)
            this.position.last.y = clamp(this.position.last.y, -this.limitY, this.limitY)

            // set new pointer last position
            this.pointer.last.x = event.x
            this.pointer.last.y = event.y
        }
    }

    onMouseOut = event => {
        this.onPointerUp(event)
    }

    onFilterItems = () => {

        // prevent filtering animation from overlapping
        if(!this.canMove) return false

        // toggle filter
        this.gridFilter.classList.toggle("active")
        this.isFiltering = !this.isFiltering

        // filter list
        const {included, excluded} = filter(this.gridItems, this.isFiltering ? el => el.dataset.available === "1" : el => el)

        // cannot move during this processus
        this.canMove = false
        
        // reset translations
        if(!parameters.isMobile){
            this.position.last.x = 0
            this.position.last.y = 0
        }

        // animate hide excluded
        gsap.to(excluded, {
            opacity: 0,
            visibility: "hidden"
        })

        // animate show included
        const options = this.createGrid(included, false)

        const tl = gsap.timeline({
            onComplete: () => {
                this.canMove = true
            }
        })
        
        // move to new place
        tl.to(included, {
            x: index => options.itemPositions[index].x + "vw",
            y: index => options.itemPositions[index].y + "vw",
            opacity: 1,
            visibility: "visible",
        })

        // resize grid
        tl.to(this.gridDraggable, {
            width: options.gridWidth + "vw",
            height: options.gridHeight + "vw"
        }, 0)

    }

    onResize = event => {

        // compute screen
        this.screen.width = window.innerWidth
        this.screen.height = window.innerHeight

        // compute padding
        this.setGridPadding()

        // compute translations
        this.computeTranslationLimit()
    }
    
    attachEvents(){

        if(!parameters.isMobile){
            this.wrapper.addEventListener("pointerdown", this.onPointerDown)
            this.wrapper.addEventListener("pointerup", this.onPointerUp)
            this.wrapper.addEventListener("pointermove", this.onPointerMove)
            document.body.addEventListener("mouseleave", this.onMouseOut)
        }

        this.gridFilter.addEventListener("click", this.onFilterItems)
        window.addEventListener("resize", this.onResize)
    }

    removeEvents(){

        this.wrapper.removeEventListener("pointerdown", this.onPointerDown)
        this.wrapper.removeEventListener("pointerup", this.onPointerUp)
        this.wrapper.removeEventListener("pointermove", this.onPointerMove)
        document.body.removeEventListener("mouseleave", this.onMouseOut)
        this.gridFilter.removeEventListener("click", this.onFilterItems)
        window.removeEventListener("resize", this.onResize)

    }

    willUnmount(){
        super.willUnmount()

        raf.unsubscribe(this.animate)

        // reset cursor
        document.body.style.cursor = ""
    }

    // Helpers
    findSuitableRowsCols(n){

        if(parameters.isMobile){

            let q = Math.floor(n / 2)
            let r = n - q * 2
            return {
                cols: 2,
                rows: q,
                rest: r
            }
            
        } else {

            let s2 = nearestSquareNumber(n)
            let s = Math.sqrt(s2) + 3
            let r = n % s
    
            return {
                cols: s,
                rows: (n - r) / s,
                rest: r
            }
        }
    }

    computeTranslationLimit(){
        // compute limit positiion
        this.limitX = this.gridSize.width < 1 ? 0 : this.GRID_LIMIT_PADDING.x + (this.gridSize.width * this.screen.width - this.screen.width) / 2

        const limitYValue2 = this.GRID_LIMIT_PADDING.y + (this.gridSize.height * this.screen.width - this.screen.height) / 2
        this.limitY = this.gridSize.height * this.screen.width < this.screen.height ? 0 : limitYValue2 
    }

    setGridPadding(){
        this.GRID_LIMIT_PADDING = {
            x: this.screen.width * .01,
            y: parameters.isMobile ? 150 : this.screen.width * .1,
        }
    }
}