import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.min.css";
import Highway from "@dogstudio/highway";
import Swiper from "swiper";

// components
import HoverTitleSimple from "../components/about/HoverTitleSimple";
import Parallax from "../components/about/Parallax";
import SpinOnScroll from "../components/about/SpinOnScroll";
import TextAppearOnScroll from "../components/about/TextAppearOnScroll";
import SliderCursor from "../components/SliderCursor";
import Blob from "../components/Blob";

// utils
import parameters from "../utils/parameters";

export default class RendererAbout extends Highway.Renderer {
  _checkSlidesVisibility = (swiper, event) => {
    const currentIndex = swiper.activeIndex;
    const slides = swiper.slides;

    slides.forEach((slide, index) => {
      if (!slide.classList.contains("swiper-slide-visible")) {
        slide.classList.add(index > currentIndex ? "right" : "left");
      } else {
        slide.classList.remove("left");
        slide.classList.remove("right");
      }
    });
  };

  onEnter() {
    
    const wrapper = this.wrap.querySelector("#page-about");
    this.wrapper = wrapper;

    this.smoothScroll = new LocomotiveScroll({
      el: wrapper,
      smooth: true,
      desktop: {
        smooth: true,
      },
      smartphone: {
        smooth: false,
      },
      tablet: {
        smooth: false,
      }
    });
    this.hoverTitle = new HoverTitleSimple(
      this.wrapper.querySelector("#bannerContainer"),
      this.smoothScroll
    );
    this.parallax = new Parallax(this.wrapper.querySelector("#parallax"));
    this.spinOnScroll = new SpinOnScroll(
      this.wrapper.querySelector("#virus"),
      this.smoothScroll
    );

    if (!parameters.isMobile) {
      this.textAppearOnScroll = new TextAppearOnScroll(
        this.wrapper,
        this.smoothScroll
      );
    }

    this.onPageAppear()
    this.smoothScroll.update()

    // after 1000ms we expect page has been fully loaded and we can update scroll container height
    // suppose to fix : image jump when locomotive scroll apply parallax effect
    setTimeout(() => {
        this.smoothScroll.update()
    }, 100)
  }

  onPageAppear(){

    this.un = this.wrapper.querySelector(".un");
    this.un.classList.add("appear");

    this.tap = this.wrapper.querySelector(".tap");
    this.tap.classList.add("appear");

  }

  onEnterCompleted() {
    if (!this.wrapper) {
      console.error("wrapper not found");
      return false;
    }


    this.swiper = new Swiper(this.wrapper.querySelector(".swiper"), {
      slidesPerView: 4,
      // spaceBetween: 56,
      slidesPerView: "auto",
      watchSlidesVisibility: true,
      slideToClickedSlide: false,
      on: {
        slideChange: this._checkSlidesVisibility,
        init: (swiper, event) => {
          this._checkSlidesVisibility(swiper, event);
          setTimeout(() => {
            this.smoothScroll.update();
          }, 500)
        },
        transitionEnd: this._checkSlidesVisibility,
        touchMove: (swiper, event) => {
          if (this.sliderCursor) {
            this.sliderCursor.onSliderTouchMove(swiper, event);
          }
        },
      },
    });
    
    if(!parameters.isMobileOrTablet){
      this.sliderCursor = new SliderCursor(
        this.wrapper.querySelector(".js__slider-cursor"),
        this.swiper.el,
        this.smoothScroll
      );
    }

    this.goContact = this.wrapper.querySelector(
      `.js__go-contact.${parameters.isMobile ? "mobile" : "desktop"}`
    );


    this.goContactBlob = new Blob({
      wrapper: this.goContact,
      size: 0.3,
      amplitude: 3,
    });

    // manage accordions on mobile
    if (!!parameters.isMobile) {
      let inputs = this.wrapper.querySelectorAll("input");

      Array.from(inputs).map((input) => {
        input.addEventListener("click", () => {
          Array.from(inputs)
            .filter((i) => i.id != input.id)
            .map((ii) => {
              ii.checked = false;
              ii.labels[0].classList.remove("is-open");
            });

          if (true == input.checked) {
            input.labels[0].classList.add("is-open");
          } else {
            input.labels[0].classList.remove("is-open");
          }
        });
      });
    }
  }

  onLeaveCompleted() {

    if(this.sliderCursor){
      this.sliderCursor.willUnmount()
    }

    if (this.smoothScroll) {
      this.smoothScroll.destroy();
    }
  }
  
}
