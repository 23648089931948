import Highway from "@dogstudio/highway"
import HomeGrid from "../components/HomeGrid"
import parameters from "../utils/parameters"
import SliderCursor from "../components/SliderCursor";

export default class RendererHomepage extends Highway.Renderer {


    onEnter(){

        const wrapper = this.wrap.querySelector("#page-home")
        this.wrapper = wrapper
        this.homeGrid = new HomeGrid(wrapper.querySelector("#home-grid"))

    }

    onEnterCompleted() { 

        if(!this.wrapper){
            console.error("wrapper not found")
            return false
        }


        // Tooltip grid items
        if(!parameters.isMobileOrTablet){
            this.TOOLTIP_DELAY = 1000
            this.tooltip = {
                delay: 1000,
                position: {x: 0, y: 0},
                element: this.wrapper.querySelector(".js__grid-tooltip"),
                label: "",
                enabled: false,
                moving: false,
                offset: 30
            }
            // events
            this.wrapper.addEventListener("mousemove", this.onMouseMove)
            this.homeGrid.gridItems.forEach(element => {
                element.addEventListener("mouseenter", this.onMouseEnter)
                element.addEventListener("mouseleave", this.onMouseLeave)
            })
        }

        // this.Transition.in({
        //     from: {remove: () => {}}, to: this.wrapper, trigger: null, done: () => {}
        // })


        // Cursor
        if(!parameters.isMobileOrTablet){
            this.sliderCursor = new SliderCursor(this.wrapper.querySelector(".js__slider-cursor"), null, null, true);
            
            this.disableCursorElements = [...this.wrapper.querySelectorAll("[data-cursor-disable]")]

            this.disableCursorElements.forEach(el => {
                el.addEventListener("mouseenter", this.onMouseEnterDisableCursor)
                el.addEventListener("mouseleave", this.onMouseLeaveDisableCursor)
            })
        }


    }

    onMouseEnterDisableCursor = e => {
        this.sliderCursor.onMouseLeave()
    }

    onMouseLeaveDisableCursor = e => {
        this.sliderCursor.onMouseEnter()
    }

    onLeave(){
        this.homeGrid.willUnmount()

        if(!parameters.isMobileOrTablet){
            // disable tooltip
            this.onMouseLeave()

            this.sliderCursor.willUnmount()

            this.disableCursorElements.forEach(el => {
                el.removeEventListener("mouseenter", this.onMouseEnterDisableCursor)
                el.removeEventListener("mouseleave", this.onMouseLeaveDisableCursor)
            })
        }


        // remove events
        this.wrapper.removeEventListener("mousemove", this.onMouseMove)
        this.homeGrid.gridItems.forEach(element => {
            element.removeEventListener("mouseenter", this.onMouseEnter)
            element.removeEventListener("mouseleave", this.onMouseLeave)
        })
    }

    onMouseMove = event => {

        if(!this.tooltip.moving) {
            this.onMouseLeave()
            return false
        }
        
        this.tooltip.position.x = event.clientX
        this.tooltip.position.y = event.clientY


        if(this.tooltip.enabled){
            this.tooltip.element.style.transform = `translate(${this.tooltip.position.x}px, ${this.tooltip.position.y}px)`
        }
    }

    // Events
    onMouseEnter = event => {
        this.tooltip.enabled = true
        this.tooltip.element.textContent = event.currentTarget.dataset.title
        this.tooltip.moving = true        

        setTimeout(() => {

            if(this.tooltip.enabled){

                this.tooltip.moving = false
                
                this.tooltip.element.style.transform = `translate(${this.tooltip.offset + this.tooltip.position.x}px, ${this.tooltip.offset + this.tooltip.position.y}px)`
                this.tooltip.element.classList.add("visible")
            }
        }, this.TOOLTIP_DELAY)
    }

    onMouseLeave = event => {
        this.tooltip.enabled = false
        this.tooltip.element.classList.remove("visible")
    }

}