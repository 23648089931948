// components
import AbstractComponent from "../AbstractComponent";

// libs
import FontFaceObserver from "fontfaceobserver";

export default class TextAppearOnScroll extends AbstractComponent {

    constructor(wrapper, smoothScroll) {
        super(wrapper)

        this.paragraphContainers = document.getElementsByClassName("text-appear-onscroll")
        this.exhibitionsContainers = document.getElementsByClassName("exhi-appear-onscroll")

        this.smoothScroll = smoothScroll

        this.didMount()
    }

    splitParagraphInLines = (currentElem) => {
        let lines = [];
        let currentLine;
        let words = currentElem.getElementsByTagName('span');
        let lastTop;
        for (let i = 0; i < words.length; i++) {
            if (words[i].offsetTop != lastTop) {
                lastTop = words[i].offsetTop;
                currentLine = [];
                lines.push(currentLine);
            }
            currentLine.push(words[i]);
        }

        currentElem.innerHTML = lines.map((line) => `<div data-scroll class="line-container ${line[0].offsetTop < window.innerHeight ? "is-inview" : ""}"><span>` + line.map((span) => span.innerText).join(' ') + '</span></div>').join(' ');
    }

    initTextAppear = () => {
        !!this.paragraphContainers && Object.values(this.paragraphContainers).map((currentElem) => {
            currentElem.innerHTML = currentElem.innerHTML.replaceAll('<br>', ' <br/> ').replace("<p>", "").replace("</p>", "").split(/\s/).map((word) => '<span>' + word + '</span>').join(' ')
            this.splitParagraphInLines(currentElem);
        })
    }

    didMount() {
        super.didMount()

        let font = new FontFaceObserver('Epilogue');

        font.load().then(() => {
            this.initTextAppear();
            this.smoothScroll.update()
        });
    }

    // onResize = e => {
    //     this.initTextAppear()
    //     // this.smoothScroll.update()
    // }

    // attachEvents() {
    //     window.addEventListener('resize', this.onResize)
    // }

    // removeEvents() {
    //     window.removeEventListener('resize', this.onResize)
    // }
}