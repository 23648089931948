

//libs
import Highway from '@dogstudio/highway'
import gsap from "gsap"

export default class ContactTransition extends Highway.Transition {

    // Built-in methods
    in({ from, to, trigger, done }) {
        from.remove()

        gsap.from(to, {
            opacity: 0,
            duration: 1,
            onComplete: done
        })

    }
  
    out({ from, trigger, done }) {

        gsap.to(from, {
            opacity: 0,
            onComplete: done
        })
    }
}